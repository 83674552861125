import { Tooltip, TooltipProps } from '@mui/material';

interface Props extends Omit<TooltipProps, 'children'> {
	maxLength?: number;
	title: string;
	spanClassName?: string;
	defaultCase?: boolean;
}

const TextWrap = (props: Props) => {
	const { maxLength, title, spanClassName, ...rest } = props;
	
	const MAX_LENGTH = maxLength || 10;
	const displayText = title?.length > MAX_LENGTH ? `${title.substring(0, MAX_LENGTH)}...` : title;

	return (
		<Tooltip title={(title?.length > MAX_LENGTH) && (props?.defaultCase ? title : title?.toUpperCase())} placement='bottom-start' {...rest}>
			<span className={spanClassName}>{props?.defaultCase ? displayText : displayText?.toUpperCase()}</span>
		</Tooltip>
	);
};

export default TextWrap;
